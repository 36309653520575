<template>
  <div class="pageContol listWrap templateList formCom">
    <div class="framePage">
      <div class="framePage-body">
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-descriptions :column="1" border size="small">
              <el-descriptions-item label="姓名">{{
                form.userName
              }}</el-descriptions-item>
              <el-descriptions-item label="身份证号">{{
                form.idcard
              }}</el-descriptions-item>
              <el-descriptions-item label="所属部门">{{
                form.department
              }}</el-descriptions-item>
              <el-descriptions-item label="培训平台">
                <span>安知职业培训平台</span>
              </el-descriptions-item>
              <el-descriptions-item label="注册时间">{{
                form.createTime
              }}</el-descriptions-item>
            </el-descriptions>
            <div v-for="(item, index) in form.projectStudyBOS" :key="index">
              <!-- <el-descriptions :column="2" border size="small">
                <el-descriptions-item
                  label="班级名称"
                  :span="4"
                  labelStyle="width:56px"
                  >{{ item.projectName }}</el-descriptions-item
                >
                <el-descriptions-item label="培训时间" labelStyle="width:56px"
                  >{{ item.startDate }}至{{
                    item.endDate
                  }}</el-descriptions-item
                >
                <el-descriptions-item
                  label="结业状态"
                  labelStyle="width:56px"
                  >{{
                    item.graduationState ? "已结业" : "未结业"
                  }}</el-descriptions-item
                >
              </el-descriptions> -->
              <table border="1" style="border-collapse: collapse">
                <tr class="trhHead" style="color: #909399; background: #fafafa">
                  <td style="width:30px;text-align:center">班级名称</td>
                  <td colspan="3" style="background: #fff; color: #606266">
                    {{ item.projectName }}
                  </td>
                </tr>
                <tr class="trhHead" style="color: #909399; background: #fafafa">
                  <td style="width:30px;text-align:center">培训时间</td>
                  <td style="background: #fff; color: #606266">
                    {{ item.startDate }}至{{ item.endDate }}
                  </td>
                  <td style="width:30px;text-align:center">结业状态</td>
                  <td style="background: #fff; color: #606266">
                    {{ item.graduationState ? "已结业" : "未结业" }}
                  </td>
                </tr>
                <tr class="trhHead" style="color: #909399; background: #fafafa">
                  <td ><span>序号</span></td>
                  <td ><span>课程名称</span></td>
                  <td ><span>学时</span></td>
                  <td ><span>成绩</span></td>
                </tr>
                <tr
                  class="trhHead"
                  style="color: #606266"
                  v-for="(item1, index1) in item.courseStudyBOS"
                  :key="index1"
                >
                  <td >{{ index1 + 1 }}</td>
                  <td >{{ item1.courseName }}</td>
                  <td >{{ item1.studyLessonNum }}</td>
                  <td >{{ item1.paperScore }}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import List from "@/mixins/List";

export default {
  name: "ExaminationDetails",
  components: {
    // Empty,
    // player2
  },
  mixins: [List],
  data() {
    return {
      data: [],
      player: null,
      form: {},
    };
  },
  created() {
    this.$nextTick(() => {
      this.getDatas();
    });
  },
  mounted() {},
  computed: {},
  methods: {
    getDatas() {
      const params = {
        json: this.$route.query.json,
      };
      this.$post("/eighteenEnterprise/userCode/getH5StudyInfo", params).then(
        (res) => {
          ;
          this.form = {
            ...res.data,
          };
        }
      );
    },
  },
};
</script>
<style lang="less" scoped>
table {
  border: none;
}
.trhHead {
  td {
    padding: 8px 10px;
    font-size: 12px;
    border: 1px solid #ebeef5;
  }
}
.contentStyle {
  width: auto;
}
.courseName {
  width: 100px !important;
}

/deep/.el-descriptions-item__cell {
  text-align: center;
}
/deep/.labelStyle {
  width: 56px !important;
}
</style>

